import React, { Component } from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

export default class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const rootUrl = process.env.GATSBY_API_URL;
        const { photoIndex, isOpen } = this.state;
        const images = this.props.gallery.map(el => rootUrl + el.url);

        return (
            <div className="container">
                <div className="row gallery-wrapper">
                    {images.map((url, index) => (
                        <img className="col-lg-3 col-md-6 col-12 gallery-image" src={url} alt='Obraz galerii' onClick={() => this.setState({ isOpen: true, photoIndex: index })}/>
                    ))}

                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}
