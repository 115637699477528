import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import ResponsiveArticleImage from "../components/responsive-article-image";

import facebook from "../assets/images/facebook-f.svg";
import ArticleContent from "../components/article-content";
import Gallery from "../components/gallery";
import CategoryNameComponent from "../components/presentation-components/category-name.component";

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      slug
      imageSrc
      author {
        name
        image {
         id
         formatSize
         url
         width
        }
      }
      gallery {
        url
      }
      category {
        name
      }
      tags {
        name
        slug
      }
      image {
        publicURL
      }
      imageFormats {
        id
        formatSize
        url
        width
      }
    }
    site {
       siteMetadata {
         siteUrl
       }
     }
  }
`;

class  Article extends React.Component {
    render() {
        const article = this.props.data.strapiArticle;
        const site = this.props.data.site;

        const seo = {
            metaTitle: article.title,
            metaDescription: article.description,
            shareImage: article.image,
            article: true,
        };
        const fbShareLink = 'https://www.facebook.com/sharer/sharer.php?u={url}';

        return (
            <Layout seo={seo}>
                <div className="page-wrapper">
                    <div className="page-container">
                        <div className="main-foto">
                            <div className="gradient"> </div>
                            <ResponsiveArticleImage formats={article.imageFormats} alt={article.title}> </ResponsiveArticleImage>
                            <div className="foto-autor">{article.imageSrc}</div>
                            <div className="title">
                                <CategoryNameComponent category={article.category}></CategoryNameComponent>
                                <h1>{article.title}</h1>
                            </div>
                        </div>

                        <div className="page-article-wrap">
                            <h1 className="title-sm">{article.title}</h1>
                            {article.author && article.author.image ?
                                <div className="row author-wrap">
                                    <div className="author__image col-xs-1 d-flex justify-content-lg-center">
                                        <ResponsiveArticleImage formats={article.author} alt={article.author.name}> </ResponsiveArticleImage>
                                    </div>
                                    <div className="author__name col-xs-10">
                                        <h5>{article.author.name}</h5>
                                    </div>
                                </div>
                            : ''}
                            <div className={`row ${(article.author && article.author.image) ? " " : "text-thumbnail-divider"}`}>
                                <div className="social-media-share col-sm-12 col-md-12 col-lg-1 d-flex justify-content-lg-center">
                                    <a href={fbShareLink.replace('{url}', site.siteMetadata.siteUrl + '/article/' + article.slug)}><img className="share-btn" src={facebook} alt="Facebook"/></a>
                                </div>
                                <div className="article-content col-sm-12 col-md-12 col-lg-10">
                                    <div className="text-up">
                                        <Markdown source={article.description} escapeHtml={false} />
                                    </div>
                                    <div className="text">
                                        <ArticleContent articleContent={article.content}/>
                                        <Gallery gallery={article.gallery}/>
                                    </div>
                                    {article.tags.length > 0 ?
                                        <div className="row tag-wrap">
                                            <p>Powiązane tematy:</p>
                                            {article.tags.map((tag) => (
                                                <Link to={`/tag/${tag.slug}`}>
                                                    <div className="tag">
                                                        {tag.name}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Article;
