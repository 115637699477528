import React from "react";

const CategoryNameComponent = ({category}) => {
  if(!category) return '';
  return (<div className="category-name">
      {category.name}
    </div>
  );
}

export default CategoryNameComponent;
