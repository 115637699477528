import React from "react";

const ResponsiveArticleImage = ({formats, imageFormats, alt}) => {
  if (!!formats && !imageFormats) {
    imageFormats = Object.keys(formats).map(key => {
      let currFormat = formats[key];
      if (Array.isArray(currFormat)) currFormat = currFormat[0];
      return {...currFormat, formatSize: key};
    });

  }
  if (!Array.isArray(imageFormats)) return (<img alt={alt}/>);
  const rootUrl = process.env.GATSBY_API_URL;
  const srcSet = imageFormats.reduce((prev, curr, idx) =>
    prev + (idx > 0 ? ',' : '') + `${rootUrl + curr.url} ${curr.width}w`, ''
  );
  const sizes = `(max-width: 640px) 500px, (max-width: 980px) 750px, 1000px`;
  const defaultFormat = imageFormats[imageFormats.length - 1];
  const defaulsSrc = defaultFormat ? rootUrl + defaultFormat.url : '';

  return (<img srcSet={srcSet} alt={alt} src={defaulsSrc} sizes={sizes}/>);
}
export default ResponsiveArticleImage;
