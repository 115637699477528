import React from "react";

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }
        return this.replace(new RegExp(str, 'g'), newStr);
    };
}

const ArticleContent = ({articleContent}) => {

    const oembedProxy = process.env.GATSBY_OEMBED_PROXY ? ` proxy="${process.env.GATSBY_OEMBED_PROXY}"` : '';

    let content = articleContent ? articleContent
        .replaceAll(/(<\s*)oembed(\s*([^>]*)?\s*>)/gi, `$1o-embed${oembedProxy}$2`)
        .replaceAll(/(<\/)oembed(\s*([^>]*)?\s*>)/gi, `$1o-embed$2`)
        .replaceAll('<img src="/uploads/', '<img style="height: 480px; width: 720px; object-fit: cover;"  src="' + `${process.env.GATSBY_API_URL}`+ "/uploads/")
    : '';
    return(<div dangerouslySetInnerHTML={{__html: content}} />);
}

export default ArticleContent;
